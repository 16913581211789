'use client';

import { Popover } from '@headlessui/react';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const TextButton = ({
    icon,
    iconSize = 1,
    className,
    isPrimary = true,
    isSmall = false,
    isEnabled = true,
    onClick,
    children,
    isPopoverButton = false,
    tabIndex,
}: {
    icon: string;
    iconSize?: number;
    className?: string;
    isPrimary?: boolean;
    isSmall?: boolean;
    isEnabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode | string;
    isPopoverButton?: boolean;
    tabIndex?: number;
}) => {
    const Comp = isPopoverButton ? Popover.Button : 'button';

    return (
        <TextButtonWrapper>
            <Comp
                onClick={onClick}
                className={twMerge(
                    'flex items-center justify-center gap-1.5 font-bold transition-all ease-out',
                    isSmall
                        ? 'py-2 ps-4 pe-3 text-base rounded-3xl'
                        : 'py-4 ps-8 pe-5 text-xl rounded-full',
                    isPrimary
                        ? 'shadow-md hover:shadow-lg focus:shadow-lg active:shadow-md bg-gradient-to-b from-primary-text-lg-aa to-keppel-text-lg-aa text-white'
                        : 'inner-border-2 inner-border-primary-800/50 text-primary-800',
                    isEnabled ? 'opacity-100' : 'opacity-50 cursor-not-allowed',
                    className,
                )}
                disabled={!isEnabled}
                tabIndex={tabIndex}
            >
                {children}
                <Icon path={icon} size={iconSize ?? 1} />
            </Comp>
        </TextButtonWrapper>
    );
};

export const TextButtonWrapper = ({
    isEnabled = true,
    ...props
}: {
    isEnabled?: boolean;
} & React.ComponentProps<typeof motion.div>) => {
    const reduceMotion = useReducedMotion();
    return (
        <motion.div
            className="flex justify-center"
            initial={{
                scale: !reduceMotion ? 0.5 : 1,
                opacity: 0,
            }}
            animate={{
                scale: 1,
                opacity: 1,
            }}
            whileHover={
                isEnabled
                    ? {
                          scale: !reduceMotion ? 1.05 : 1,
                          opacity: 0.9,
                      }
                    : {}
            }
            whileTap={
                isEnabled
                    ? {
                          scale: !reduceMotion ? 0.95 : 1,
                          opacity: 0.8,
                      }
                    : {}
            }
        >
            {props.children}
        </motion.div>
    );
};

export default TextButton;
